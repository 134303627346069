<template>
  <div>
    <h6 class="pa-0">
      {{ title }}
      <v-btn
        x-small
        icon
        @click="getSessions"
        :loading="loading"
        :disabled="blockRefresh"
      >
        <v-icon x-small>mdi-refresh</v-icon>
      </v-btn>
    </h6>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="rtl-ps-none ps scroll"
      :style="{ maxHeight }"
    >
      <v-list v-if="loaded" dense>
        <v-list-item v-for="session in data" :key="session.id">
          <v-list-item-avatar class="ma-1">
            <v-icon large>
              {{
                devicesIcons[osFamily(session.loginAttempt.userAgent)] ||
                "mdi-help"
              }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ session.loginAttempt.userAgent | userAgent }}
                  </span>
                </template>
                <span>{{ session.loginAttempt.userAgent | osFullInfo }}</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip
                v-if="session.id === sessionId"
                x-small
                label
                color="green"
                class="mr-1"
              >
                Atual
              </v-chip>
              <template v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-block" v-bind="attrs" v-on="on">
                      Conectado {{ session.createdAt | dateFromNow }}
                    </span>
                  </template>
                  <span>
                    {{ session.createdAt | date("DD/MM/YYYY HH:mm:ss") }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-block" v-bind="attrs" v-on="on">
                      Último uso {{ session.lastUsed | dateFromNow }}
                    </span>
                  </template>
                  <span>
                    {{ session.lastUsed | date("DD/MM/YYYY HH:mm:ss") }}
                  </span>
                </v-tooltip>
              </template>

              <v-chip color="secondary" x-small label outlined class="ml-1">
                IP: {{ session.loginAttempt.ip | ip }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              small
              @click="fLogout(session.id)"
              :loading="logout.loading == session.id"
              :disabled="session.id === sessionId || !!logout.loading"
            >
              <v-icon small>mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-btn
        v-else
        :loading="loading"
        small
        block
        color="primary"
        @click="getSessions"
      >
        Carregar últimos logins
      </v-btn>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import ACCOUNT from "@/services/account";
import platform from "platform";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    data: [],
    loading: false,
    loaded: false,
    logout: {
      loading: false,
      error: false,
    },
    blockRefresh: null,
    devicesIcons: {
      Windows: "mdi-microsoft-windows",
      "Windows Server 2008 R2 / 7": "mdi-microsoft-windows",
      "Windows Server 2008 / Vista": "mdi-microsoft-windows",
      "Windows XP": "mdi-microsoft-windows",
      "OS X": "mdi-apple",
      Linux: "mdi-linux",
      Ubuntu: "mdi-ubuntu",
      Debian: "mdi-debian",
      Fedora: "mdi-fedora",
      "Red Hat": "mdi-redhat",
      SuSE: "mdi-help",
      Android: "mdi-android",
      iOS: "mdi-apple-ios",
      "Windows Phone": "mdi-microsoft-windows",
    },
  }),
  computed: {
    ...mapGetters("auth", ["session"]),
    sessionId() {
      return this.session.id;
    },
  },
  methods: {
    async fLogout(sessionId) {
      try {
        this.logout.loading = sessionId;
        await ACCOUNT.session.remove(sessionId);

        this.data = this.data.filter((session) => session.id !== sessionId);
      } catch (error) {
        console.log(error);
      } finally {
        this.logout.loading = false;
      }
    },
    async getSessions() {
      try {
        this.loading = true;
        const { sessions } = await ACCOUNT.session.getLated();
        this.data = sessions;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.fBlockRefresh();
      }
    },
    fBlockRefresh() {
      this.blockRefresh = true;
      setTimeout(() => {
        this.blockRefresh = false;
      }, 5000);
    },
    osFamily(value) {
      return platform.parse(value).os.family;
    },
  },
  filters: {
    dateFromNow(value) {
      // if is in the last 24 hours, show "a few seconds ago"
      moment.locale("pt-br");
      return moment(value).fromNow();
    },
    userAgent(value) {
      const p = platform.parse(value);
      return `${p.os.family} - ${p.name}`;
    },
    osFullInfo(value) {
      const p = platform.parse(value);
      return `${p.os.family} ${p.os.version} - ${p.name} - ${p.version}`;
    },
    ip(value) {
      return value.replace("::ffff:", "");
    },
  },
  mounted() {
    this.getSessions();
  },
  props: {
    title: {
      type: String,
      default: "Aparelhos conectados",
    },
    maxHeight: {
      type: String,
      default: "250px",
    },
  },
};
</script>

<style></style>
