<template>
  <div>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll"
      style="height: 100%"
    >
      <div class="pa-5 relative">
        <v-app-bar :dark="$vuetify.theme.dark" class="pt-1" fixed flat>
          <div class="d-flex justify-space-between align-center w-full">
            <h6 class="ma-0">Minha conta</h6>
            <slot name="userDrawerCloseButton"></slot>
          </div>
        </v-app-bar>
      </div>

      <v-card outlined class="pa-2 mt-7 ma-3">
        <div class="heading-label pa-0 mb-4">Conta</div>
        <div class="d-flex align-center mb-2">
          <base-avatar
            :size="50"
            class="mr-2"
            :seed="user.id"
            :src="user.photo"
          />
          <div>
            <p class="font-weight-medium ma-0">{{ user.name }}</p>
            <p class="body-2 text--disabled mb-2">{{ user.phone }}</p>
          </div>
        </div>
        <!-- <v-btn block color="primary" @click="photoUpload" class="mt-1">
          <v-icon left small>mdi-camera</v-icon>
          Alterar foto do perfil
        </v-btn> -->
        <v-btn
          small
          block
          color="success"
          @click="importWhatsappPhoto"
          class="mt-1"
        >
          <v-icon left small>mdi-whatsapp</v-icon>
          Importar foto do Whatsapp
        </v-btn>
        <v-btn
          v-if="user.photo"
          small
          block
          class="mt-1"
          :style="
            removePhoto.error
              ? 'background-color: #800020'
              : 'background-color: #304155'
          "
          style="color: white"
          @click="runRemovePhoto"
          :loading="removePhoto.loading"
        >
          {{
            removePhoto.confirm
              ? "Confirmar remoção "
              : "Remover foto do perfil"
          }}
        </v-btn>
      </v-card>
      <v-card outlined class="pa-2 ma-3">
        <div class="heading-label pa-0 mb-4">Segurança</div>
        <v-btn block color="primary" @click="openSecurity" class="mt-1">
          <v-icon left small>mdi-lock</v-icon>
          Painel de segurança
        </v-btn>
      </v-card>
      <v-card outlined class="pa-2 ma-3" v-if="!user.birthday">
        <div class="heading-label pa-0 mb-4">Avisos</div>
        <v-btn block color="warning" @click="openBirthday" class="mt-1">
          <v-icon left small>mdi-alert</v-icon>
          Preencher aniversário
        </v-btn>
      </v-card>
    </vue-perfect-scrollbar>
    <security />
    <birthday />
    <import-whatsapp-photo />
  </div>
</template>

<script>
import ACCOUNT from "@/services/account";
import { mapActions, mapGetters } from "vuex";

import ImportWhatsappPhoto from "../../components/global/account/ImportWhatsappPhoto.vue";
import Birthday from "../../components/global/account/Birthday.vue";
import Security from "../../components/global/account/Security.vue";

export default {
  name: "UserDrawer",
  components: { ImportWhatsappPhoto, Security, Birthday },
  data() {
    return {
      logins: {
        data: [],
        loading: false,
        loaded: false,
      },

      removePhoto: {
        timeout: null,
        loading: false,
        confirm: false,
        error: false,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["setUserPhoto"]),
    importWhatsappPhoto() {
      this.$emit("import-whatsapp-photo");
    },
    openSecurity() {
      this.$emit("account-security");
    },
    openBirthday() {
      this.$emit("account-birthday");
    },
    async runRemovePhoto() {
      this.removePhoto.error = false;
      if (this.removePhoto.confirm) {
        try {
          this.removePhoto.loading = true;
          this.removePhoto.confirm = false;
          clearTimeout(this.removePhoto.timeout);

          await ACCOUNT.removePhoto();
          this.setUserPhoto(null);
        } catch (e) {
          console.log(e);
          this.removePhoto.error = true;
        } finally {
          this.removePhoto.loading = false;
        }
      } else {
        this.removePhoto.confirm = true;
        this.removePhoto.timeout = setTimeout(() => {
          this.removePhoto.confirm = false;
        }, 3000);
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style scoped></style>
