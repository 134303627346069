<template>
  <v-dialog v-model="dialog" max-width="510px" :persistent="loading">
    <v-card :loading="loading">
      <v-card-title> Atualizar data de aniversário </v-card-title>
      <v-card-text>
        <v-alert dense color="" text>
          Informe sua data de nascimento para garantirmos que nossos usuários
          tenham a melhor experiência possível e atendam aos requisitos de idade
          para uso de nosso serviço.
        </v-alert>
        <v-text-field-simplemask
          v-model="birthday"
          label="Data de nascimento"
          v-bind:properties="{
            prefix: '',
            suffix: '',
            hideDetails: 'auto',
            outlined: true,
            placeholder: 'DD/MM/AAAA',
            type: 'tel',
            rules: birthdayRules,
            disabled: innerLoading,
            dense: true,
          }"
          v-bind:options="{
            inputMask: '##/##/####',
            outputMask: '##/##/####',
            empty: '',
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
          }"
        ></v-text-field-simplemask>
      </v-card-text>
      <v-card-actions>
        <div class="w-full justify-center d-flex text-center">
          <v-btn :disabled="loading" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            depressed
            @click="save()"
            :disabled="!isValid"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ACCOUNT from "@/services/account";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data: () => ({
    dialog: false,
    error: false,
    success: false,
    loading: false,
    birthday: null,
    innerLoading: false,
  }),
  methods: {
    ...mapActions("auth", ["renewToken"]),
    async save() {
      try {
        this.loading = true;

        const response = await ACCOUNT.update({
          birthday: moment(this.birthday, "DD/MM/AAAA").toISOString(),
        });

        await this.renewToken();
        this.dialog = false;
      } catch (e) {
        console.log(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    open() {
      this.dialog = true;
      this.loading = false;
      this.error = false;
      this.success = false;
    },
    validateAndCheckDate(dateString) {
      if (!dateString) return false;
      const [day, month, year] = dateString.split("/").map(Number);
      if (
        isNaN(day) ||
        isNaN(month) ||
        isNaN(year) ||
        year < 1900 ||
        year > 3000 ||
        month < 1 ||
        month > 12
      )
        return false;
      const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;
      if (day < 1 || day > monthLength[month - 1]) return false;
      const today = new Date();
      const inputDate = new Date(year, month - 1, day);
      return inputDate <= today;
    },
  },
  computed: {
    isValid() {
      return this.validateAndCheckDate(this.birthday);
    },
    birthdayRules() {
      return [
        (v) => !!v || "A data de nascimento é obrigatória",
        (v) => this.validateAndCheckDate(v) || "Data inválida",
      ];
    },
  },
  mounted() {
    this.$parent.$on("account-birthday", this.open);
  },
};
</script>

<style></style>
