var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"510px","persistent":_vm.loading},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Atualizar data de aniversário ")]),_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","color":"","text":""}},[_vm._v(" Informe sua data de nascimento para garantirmos que nossos usuários tenham a melhor experiência possível e atendam aos requisitos de idade para uso de nosso serviço. ")]),_c('v-text-field-simplemask',{attrs:{"label":"Data de nascimento","properties":{
          prefix: '',
          suffix: '',
          hideDetails: 'auto',
          outlined: true,
          placeholder: 'DD/MM/AAAA',
          type: 'tel',
          rules: _vm.birthdayRules,
          disabled: _vm.innerLoading,
          dense: true,
        },"options":{
          inputMask: '##/##/####',
          outputMask: '##/##/####',
          empty: '',
          applyAfter: false,
          alphanumeric: false,
          lowerCase: false,
        }},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1),_c('v-card-actions',[_c('div',{staticClass:"w-full justify-center d-flex text-center"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"depressed":"","disabled":!_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }