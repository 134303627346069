<template>
  <div>
    <h6>{{ title }}</h6>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="rtl-ps-none ps scroll"
      :style="{ maxHeight }"
    >
      <v-list v-if="loaded" dense>
        <v-list-item v-for="login in data" :key="login.id">
          <v-list-item-avatar class="ma-1">
            <v-icon large>
              {{ devicesIcons[osFamily(login.userAgent)] || "mdi-help" }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ login.userAgent | userAgent }}
                  </span>
                </template>
                <span>{{ login.userAgent | osFullInfo }}</span>
              </v-tooltip>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ login.createdAt | dateFromNow }}
                  </span>
                </template>
                <span>
                  {{ login.createdAt | date("DD/MM/YYYY HH:mm:ss") }}
                </span>
              </v-tooltip>
              <v-chip color="secondary" x-small label outlined class="ml-1">
                IP: {{ login.ip | ip }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn
        v-else
        :loading="loading"
        small
        block
        color="primary"
        @click="getLogins"
      >
        Carregar últimos logins
      </v-btn>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import ACCOUNT from "@/services/account";
import platform from "platform";
import moment from "moment";

export default {
  data: () => ({
    data: [],
    loading: false,
    loaded: false,
    devicesIcons: {
      Windows: "mdi-microsoft-windows",
      "Windows Server 2008 R2 / 7": "mdi-microsoft-windows",
      "Windows Server 2008 / Vista": "mdi-microsoft-windows",
      "Windows XP": "mdi-microsoft-windows",
      "OS X": "mdi-apple",
      Linux: "mdi-linux",
      Ubuntu: "mdi-ubuntu",
      Debian: "mdi-debian",
      Fedora: "mdi-fedora",
      "Red Hat": "mdi-redhat",
      SuSE: "mdi-help",
      Android: "mdi-android",
      iOS: "mdi-apple-ios",
      "Windows Phone": "mdi-microsoft-windows",
    },
  }),
  methods: {
    async getLogins() {
      try {
        this.loading = true;
        const { logins } = await ACCOUNT.lastLogins();
        this.data = logins;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    osFamily(value) {
      return platform.parse(value).os.family;
    },
  },
  filters: {
    ip(value) {
      return value.replace("::ffff:", "");
    },
    dateFromNow(value) {
      // if is in the last 24 hours, show "a few seconds ago"
      moment.locale("pt-br");
      return moment(value).fromNow();
    },
    userAgent(value) {
      const p = platform.parse(value);
      return `${p.os.family} - ${p.name}`;
    },
    osFullInfo(value) {
      const p = platform.parse(value);
      return `${p.os.family} ${p.os.version} - ${p.name} - ${p.version}`;
    },
  },
  props: {
    title: {
      type: String,
      default: "Últimos logins",
    },
    maxHeight: {
      type: String,
      default: "250px",
    },
  },
};
</script>

<style></style>
