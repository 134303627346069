<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="loading"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    overlay-opacity="0.9"
  >
    <v-card :loading="loading">
      <v-card-title> <h5>Painel de Segurança</h5> </v-card-title>
      <v-card-text class="d-flex flex-column" style="gap: 0.5rem">
        <v-card outlined class="pa-4">
          <h6 class="">Alterar senha</h6>
          <v-alert dense color="" text>
            Altere sua senha periodicamente para manter sua conta segura. Use
            uma senha forte que você não usa em nenhum outro lugar.
          </v-alert>
          <v-btn block color="primary" @click="goToChangePassword">
            Alterar senha
          </v-btn>
        </v-card>
        <v-card outlined class="pa-4">
          <h6 class="">Configurar {{ webAuthn }}</h6>
          <v-alert dense color="" text>
            A autenticação por {{ webAuthn }} é uma forma segura e prática de
            acessar sua conta. Você pode usar o {{ webAuthn }} para acessar sua
            conta sem precisar digitar sua senha.
          </v-alert>
          <v-alert v-if="!webAuthn" dense color="error" text>
            A autenticação por {{ webAuthn }} não está disponível para o seu
            dispositivo.
          </v-alert>
          <v-btn v-else block color="primary" @click="goToWebAuthn">
            Configurar
          </v-btn>
        </v-card>
        <v-card outlined class="pa-4">
          <last-sessions />
          <v-divider class="mb-2" />
          <last-logins />
        </v-card>
      </v-card-text>
      <v-card-actions class="px-6 pb-2">
        <div class="w-full text-center">
          <v-btn :disabled="loading" block text @click="dialog = false">
            Fechar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ACCOUNT from "@/services/account";
import { mapActions, mapGetters } from "vuex";
import platform from "platform";

import LastSessions from "./LastSessions.vue";
import LastLogins from "./LastLogins.vue";

export default {
  components: { LastSessions, LastLogins },
  data: () => ({
    dialog: false,
    error: false,
    success: false,
    loading: false,
    webAuthnTecnologies: {
      Windows: "Windows Hello",
      "Windows Server 2008 R2 / 7": "Windows Hello",
      "Windows Server 2008 / Vista": "Windows Hello",
      "Windows XP": false,
      "OS X": "Touch ID",
      Linux: false,
      Ubuntu: false,
      Debian: false,
      Fedora: false,
      "Red Hat": false,
      SuSE: false,
      Android: "Leitor de impressão digital",
      iOS: "Touch ID / Face ID",
      "Windows Phone": false,
    },
  }),
  methods: {
    goToWebAuthn() {
      this.$router.push({
        name: "webauthn.create",
      });
    },
    goToChangePassword() {
      this.$router.push({
        name: "forgot-password",
        query: {
          phone: this.user.phone.replace(/\D/g, ""),
          ddi: this.user.ddi,
        },
      });
    },
    open() {
      this.dialog = true;
      this.loading = false;
      this.error = false;
      this.success = false;
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    webAuthn() {
      if (!navigator.credentials) return false;
      return this.webAuthnTecnologies[platform.os.family] || "WebAuthn";
    },
  },
  mounted() {
    this.$parent.$on("account-security", this.open);
  },
};
</script>

<style></style>
